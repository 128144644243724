

.plan {
    padding: 2em;
    border-radius: 1em;
    margin: 0 0.5em 1em;
    text-align: center;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background: linear-gradient(-45deg, #00A1AB, #3741A0);
    color: #fff;

}
.plan:hover{
    background: linear-gradient(-45deg, #E5E3E8, #FAFAFA); 
    color: black;
}

.btn1 {
    color: #FFF;
    background: #00A1AB;
    padding: 0.938rem 1.5rem;
    border-radius: 1.125rem;
    font-weight: 400;
    font-size: 1rem;
}
.btn1:hover{
    color: black;
}
.plan-title {
    text-transform: uppercase;
    margin: 0 0 1em;
    font-size: 24px;
    font-weight: 700;

}

.plan-price {
    margin: 0;
    font-size: 3rem;
    line-height: 1;
    font-weight: 900;

}

.plan-price span {
    display: block;
    font-size: 1.5625rem;
    font-weight: 300;
}

.plan-description {
    margin: 2em 0;
    line-height: 1.5;
}